<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="f-form lp-open-day__form"
    greedy
    @submit.prevent="onSubmit"
  >
    <div v-if="$q.screen.gt.sm" class="lp-open-day__time-slot-container">
      <div class="lp-open-day__time-slot-date-container">
        <q-icon name="event" color="primary" class="lp-open-day__time-slot-icon" />
        <span
          class="lp-open-day__time-slot-date-text"
          v-text="toDate(formData.date, false, 'DD.MM.YYYY')"
        />
      </div>

      <q-separator vertical dense color="primary" />

      <div>
        <span
          class="lp-open-day__time-slot-time-text"
          v-text="getTimeMeridian(formData.timeSlot)"
        />
      </div>
    </div>

    <LPFODSlotPicker v-else v-model="formData" />

    <FInputSelect
      ref="elFInputSelect"
      v-model="formData.customerType"
      :error="!!errors?.customerType?.[0]"
      :error-message="errors?.customerType?.[0]"
      :label="t('LPOpenDay.fields.customerType.label')"
      :no-select-option-text="t('LPOpenDay.fields.customerType.placeholder')"
      :options="customerTypeOptions"
      emit-value
      map-options
      outlined
      popup-content-class="f-input-select__content-class"
      :rules="[requiredDefault]"
    />

    <div class="f-form_grid_columns">
      <FInput
        v-model.trim="formData.contact.firstName"
        :disable="fieldDisabled"
        :error="!!errors['contact.firstName']?.[0]"
        :error-message="errors['contact.firstName']?.[0]"
        :label="t('LPOpenDay.fields.firstName.label')"
        :placeholder="t('LPOpenDay.fields.firstName.placeholder')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
        :required="true"
      />

      <FInput
        v-model.trim="formData.contact.lastName"
        :disable="fieldDisabled"
        :error="!!errors['contact.lastName']?.[0]"
        :error-message="errors['contact.lastName']?.[0]"
        :label="t('LPOpenDay.fields.lastName.label')"
        :placeholder="t('LPOpenDay.fields.lastName.placeholder')"
        lazy-rules
        outlined
        :rules="[requiredDefault]"
        :required="true"
      />
    </div>

    <FInput
      v-model.trim="formData.contact.email"
      :disable="fieldDisabled"
      :error="!!errors['contact.email']?.[0]"
      :error-message="errors['contact.email']?.[0]"
      :label="t('LPOpenDay.fields.email.label')"
      :placeholder="t('LPOpenDay.fields.email.placeholder')"
      lazy-rules
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />

    <FInputPhone
      v-model="formData.contact.phone"
      :disabled="fieldDisabled"
      :error="!!errors['contact.phone']?.[0]"
      :error-message="errors['contact.phone']?.[0]"
      :label="t('LPOpenDay.fields.phone.label')"
      outlined
      :rules="[requiredDefault]"
      :required="true"
    />

    <FInputTerms v-model="formData.accepts_terms" :error-message="errors.accepts_terms?.[0]" />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script lang="ts" setup>
import { useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';

import FInput from '@/components/Form/input/FInput.vue';
import FInputPhone from '@/components/Form/input/FInputPhone.vue';
import FInputSelect from '@/components/Form/input/FInputSelect.vue';
import FInputTerms from '@/components/Form/input/FInputTerms.vue';
import LPFODSlotPicker from '@/components/ListingPage/Form/fragments/LPFormOpenDay/LPFODSlotPicker.vue';
import { useApiListing } from '@/composables/api/listing';
import { useFormInputRules } from '@/composables/formInputRules';
import { useCaptcha } from '@/composables/useCaptcha';
import { useUtmSource } from '@/composables/useUtmSource';
import translation from '@/i18n/translations/components/formSteps.json';
import useAuthStore from '@/store/modules/auth';
import { useListingStore } from '@/store/modules/listing';
import type { ApiError } from '@/types/api';
import { formStepperKey, type PayloadFormStepper } from '@/types/event-bus';
import type { FormId, Next } from '@/types/formStepsFactory';
import { toDate } from '@/utils/time';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next?: Next;
  data?: Record<string, any>; // additional form data
}>();

type Errors = Record<
  | Exclude<keyof typeof formData.value, 'contact'>
  | 'contact.firstName'
  | 'contact.lastName'
  | 'contact.email'
  | 'contact.phone',
  string[]
>;

const emit = defineEmits<{
  (e: 'next', p: Next): void;
}>();

const { resolveUtmSource } = useUtmSource();
const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);
const { storeOpenDay } = useApiListing();
const { listing, isPreview } = storeToRefs(useListingStore());
const { getToken } = useCaptcha();
const { t } = useI18n(translation);

const { elQForm, required } = useFormInputRules();
const { user } = storeToRefs(useAuthStore());

const requiredDefault = required();

const bus = useEventBus(formStepperKey);
const loading = ref(false);

const errors = ref<Partial<Errors>>({});

const customerTypeOptions = computed(() => [
  {
    label: t('LPOpenDay.fields.customerType.options.individual'),
    value: 'Individual',
  },
  {
    label: t('LPOpenDay.fields.customerType.options.agent'),
    value: 'Agent',
  },
]);

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value,
  form: `f-${props.formId}`,
  label: t('LPOpenDay.btn.submit'),
  loading: loading.value,
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const formData = ref({
  contact: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  customerType: 'Individual',
  date: props.data?.date || '',
  timeSlot: props.data?.timeSlot || '',
  r_token: '',
  accepts_terms: false,
  listingId: listing.value?.id || -1,
  utmSource: resolveUtmSource(),
});

onMounted(() => {
  console.log(formData.value);
});

const fieldDisabled = computed(() => formData.value.customerType === 'Individual' || loading.value);

const onSubmit = async () => {
  errors.value = {};

  if (isPreview.value) return;

  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;

  formData.value.r_token = await getToken('submitOpenDay');

  storeOpenDay({ ...formData.value, ...(props.next?.formData || {}) })
    .then(() => {
      emit('next', props.next || {});
    })
    .catch((error: ApiError<keyof typeof formData.value>) => {
      if ('response' in error && error.response) {
        const { response } = error;

        switch (response.status) {
          case 422:
            errors.value = response.data.errors;
            break;
          default:
            break;
        }
      } else {
        // TODO: handle network error
      }
    })
    .finally(() => {
      loading.value = false;
    });
};

const getTimeMeridian = (time: string) => {
  const makeTime = time.split('-');

  const formatTime = (timePart: string) => {
    const [hours, minutes] = timePart.split(':').map(Number);

    const meridian =
      hours >= 12 ? t('LPOpenDay.extra.pm').toUpperCase() : t('LPOpenDay.extra.am').toUpperCase();
    const formattedHours = hours % 12 === 0 ? 12 : hours % 24;

    return `${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${meridian}`;
  };

  return `${formatTime(makeTime[0])} - ${formatTime(makeTime[1])}`;
};

watchEffect(() => {
  if (formData.value.customerType === 'Individual') {
    Object.assign(formData.value.contact, {
      email: user.value?.email || '',
      firstName: user.value?.first_name || '',
      lastName: user.value?.last_name || '',
      phone: user.value?.phone || '',
    });
  } else {
    Object.assign(formData.value.contact, {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
    });
  }
});

const busListener = (e: PayloadFormStepper) => {
  if (e.factoryId === 'lp-open-day') {
    switch (e.event) {
      case 'abort':
        loading.value = false;
        break;
      case 'complete':
        loading.value = false;
        break;
      default:
        break;
    }
  }
};

bus.on(busListener);

onBeforeUnmount(() => {
  bus.off(busListener);
});
</script>

<style lang="scss">
@use 'sass:map';

.lp-open-day__time-slot-container {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  padding: 0.25rem;
  font-size: 1rem;
  color: $primary;
  background: $primary-2;
  border-radius: map.get($radius-sizes, 'sm');
}

.lp-open-day__time-slot-date-container {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
}
</style>
