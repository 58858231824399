<template>
  <div class="lp-cta-widget-terms">
    <q-checkbox
      v-model="modelValue"
      :color="errorMessage ? 'negative' : 'primary'"
      :false-value="false"
      keep-color
      size="md"
      :true-value="true"
    >
      <template #default>
        <div class="lp-cta-widget-terms--container text-body2">
          <span v-text="tCtaTerms('agreeToOur')" />

          <a
            class="link-undecorated"
            :href="localizedTermsUrl"
            target="_blank"
            rel="noopener noreferrer"
            @click.stop
            v-text="tCtaTerms('termsAndConditions')"
          />

          <span v-text="tCtaTerms('and')" />

          <a
            class="link-undecorated"
            :href="localizedPrivacyUrl"
            target="_blank"
            rel="noopener noreferrer"
            @click.stop
            v-text="tCtaTerms('privacyPolicy')"
          />
        </div>
      </template>
    </q-checkbox>

    <div
      v-if="errorMessage"
      class="q-field__messages col error q-field__bottom"
      v-text="errorMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import useLocalizedLinks from '@/composables/useLocalizedLinks';
import translations from '@/i18n/translations/components/listingCtaTerms.json';
import type { FInputTermsProps } from '@/types/formInput';

withDefaults(defineProps<FInputTermsProps>(), { align: 'start' });

const modelValue = defineModel<boolean>({ required: true });

const { t: tCtaTerms } = useI18n(translations);
const { localizedTermsUrl, localizedPrivacyUrl } = useLocalizedLinks();
</script>

<style lang="scss">
.lp-cta-widget-terms {
  width: 100%;

  .lp-cta-widget-terms--container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.1875rem;
    justify-content: v-bind('align');

    a {
      color: $primary;
    }
  }

  .error {
    padding-top: 0;
    color: $negative;
  }

  .q-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .q-checkbox__label {
    padding: 8px 7px 8px 8px;
  }
}
</style>
