<template>
  <div class="f-input f-input-select">
    <div class="f-input__label" v-text="label" />

    <q-select v-bind="qSelectProps" v-model="modelValue">
      <template v-for="(_, slotName) in getSlots($slots)" #[slotName]="slotProps">
        <slot :name="slotName" v-bind="slotProps || {}" />
      </template>

      <template v-if="!modelValue && noSelectOptionText" #selected>
        <span class="f-input__no-select" v-text="noSelectOptionText" />
      </template>
    </q-select>
  </div>
</template>

<script setup lang="ts">
import type { QSelectProps, QSelectSlots } from 'quasar';
import { computed } from 'vue';

import type { FInputSelectProps } from '@/types/formInput';

const props = defineProps<FInputSelectProps>();

const modelValue = defineModel<QSelectProps['modelValue']>('modelValue');

const qSelectProps = computed(() => {
  const p = { ...props };

  delete p.label;

  return p;
});

// hacks needed to preserve slot name and prop types.
const getSlots = (slots: unknown) => slots as QSelectSlots;
</script>
