<template>
  <div class="lp-form-auction-bid__container">
    <q-icon class="lp-form-auction-bid__icon" name="auction_round" />
    <div class="lp-form-auction-bid__title" v-text="titleText" />
    <div class="lp-form-auction-bid__subtitle" v-text="subtitleText" />
    <div
      v-if="!listing?.auction?.dateToBeAnnounced"
      class="f-form-auction-bid__container-countdown"
    >
      <div class="q-mb-lg">
        <div class="listing-body__text--subtitle q-mb-xs" v-text="t('LPAuctionBid.title')" />

        <div
          class="lp-form-auction-bid__container--text"
          v-text="t('LPAuctionBid.extra.timeRemaining')"
        />
      </div>

      <vue-countdown
        v-slot="timer"
        :time="auctionBidUnavailable ? 0 : auctionTime"
        :transform="transformSlotProps"
        class="v-countdown--container"
      >
        <div v-for="key in countdownKeys" :key="key" class="v-countdown--content">
          <div class="v-countdown--content-number" v-text="timer[key]" />
          <div class="v-countdown--content-text" v-text="t(`LPAuctionBid.extra.${key}`)" />
        </div>
      </vue-countdown>
    </div>

    <q-btn v-if="!auctionBidUnavailable" v-bind="btnPropsSubmit" @click="dialog = true" />

    <DialogFormStepper
      v-if="!isPreview"
      v-model="dialog"
      :factory-id="factoryId"
      persistent
      :steps="formStepsLpAuctionBid"
    />
  </div>
</template>

<script setup lang="ts">
import VueCountdown from '@chenfengyuan/vue-countdown';
import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import DialogFormStepper from '@/components/Dialog/DialogFormStepper.vue';
import { useTheme } from '@/composables/theme';
import { useStepsLpAuctionBid } from '@/factories/formStepsFactory/lpAuctionBid';
import translations from '@/i18n/translations/components/formSteps.json';
import { useListingStore } from '@/store/modules/listing';
import { toDateTime } from '@/utils/time';

const { getComponentConfig } = useTheme();
const { CtaWidget: config } = getComponentConfig();
const { locale, t } = useI18n(translations);

const { listing, isPreview } = storeToRefs(useListingStore());

const { factoryId, getFormStepsLpAuctionBid } = useStepsLpAuctionBid();

const formStepsLpAuctionBid = getFormStepsLpAuctionBid();

const dialog = ref(false);

const countdownKeys = ['days', 'hours', 'minutes'];

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl q-mt-lg2',
  color: 'primary',
  label: t('LPAuctionBid.btn.submit'),
  noCaps: true,
  padding: '0.8rem',
  rel: 'noopener noreferrer',
  target: '_blank',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const auctionTime = computed(() => {
  const time = dayjs(listing.value?.auction?.auctionDate());
  return time.diff(dayjs());
});

const transformSlotProps = (slotProps: Record<string, number>) => {
  const formattedProps: Record<string, string> = {};
  Object.entries(slotProps).forEach(([key, value]) => {
    formattedProps[key] = value < 10 ? `0${value}` : `${value}`;
  });

  return formattedProps;
};

const auctionBidUnavailable = computed(
  () =>
    listing.value?.auction?.getWorkingDaysDiff() === 0 && !listing.value?.auction?.dateToBeAnnounced
);

const subtitleText = computed(() =>
  listing.value?.auction?.getWorkingDaysDiff() === 0 && !listing.value.auction.dateToBeAnnounced
    ? t('LPAuctionBid.auctionBidUnavailable')
    : ''
);

const titleText = computed(() => {
  if (listing.value?.auction?.dateToBeAnnounced) {
    return t('LPAuctionBid.auctionBidToBeAnnounced');
  }

  return (
    toDateTime(
      listing.value?.auction?.auctionDate() || '',
      false,
      config.auctionRound.dateFormat,
      locale.value
    ) || ''
  );
});
</script>

<style lang="scss">
@use 'sass:map';

.lp-auction-bid__form {
  padding: 1rem;
  text-align: center;
  background: $info-1;
  border: 1px solid $primary;
  border-radius: map.get($radius-sizes, 'md');

  .lp-form-auction-bid__icon {
    padding: 0.625rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: $primary;
    background: white;
    border-radius: 50%;
  }

  .lp-form-auction-bid__container--text {
    font-size: 0.75rem;
    font-weight: 500;
    color: $accent;
  }

  .lp-form-auction-bid__title {
    margin-bottom: 1.5rem;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 160%;
    color: $primary;
  }

  .stepper-form--container {
    padding-top: 0;
  }

  .lp-form-auction-bid__subtitle {
    font-size: 1.125rem;
    font-weight: 800;
    color: black;
  }
}

.v-countdown--container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.5rem;
  color: black;
  background: white;
  border-radius: 0.5rem;
}

.v-countdown--content {
  display: flex;
  flex-direction: column;
}

.v-countdown--content-number {
  font-size: 2rem;
  font-weight: 800;
  line-height: 140%;
}

.v-countdown--content-text {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 140%;
  color: $accent;
}
</style>
