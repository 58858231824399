<template>
  <div class="f-input">
    <div class="f-input__label" v-text="label" />

    <q-input v-bind="qInputProps" v-model="modelValue" no-error-icon>
      <template v-for="(_, slotName) in getSlots($slots)" #[slotName]>
        <slot :name="slotName" />
      </template>
    </q-input>
  </div>
</template>

<script setup lang="ts">
import type { QInputProps, QInputSlots } from 'quasar';
import { computed } from 'vue';

import type { FInputProps } from '@/types/formInput';

const props = defineProps<FInputProps>();

const modelValue = defineModel<QInputProps['modelValue']>('modelValue', { required: true });

const qInputProps = computed(() => {
  const p = { ...props };

  delete p.label;

  return p;
});

// hacks needed to preserve slot name and prop types.
const getSlots = (slots: unknown) => slots as QInputSlots;
</script>
