import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import FormStepperCallback from '@/components/Form/FormStepperCallback.vue';
import LPFormOpenDay from '@/components/LPFormOpenDay.vue';
import { useStepsAuth } from '@/factories/formStepsFactory/auth';
import tFormStepsFactory from '@/i18n/translations/components/formStepsFactory.json';
import type { FactoryId, FormStepsFactory, Next, Step } from '@/types/formStepsFactory';

export const useStepsLpOpenDay = () => {
  const factoryId: FactoryId = 'lp-open-day';

  const { t } = useI18n({ ...tFormStepsFactory, useScope: 'global' });
  const { getFormStepsAuth } = useStepsAuth();

  const getFormStepsLpOpenDay = (): FormStepsFactory => {
    const authFormSteps = getFormStepsAuth();

    return computed<Step[]>(() => [
      ...authFormSteps.value,
      {
        component: () => LPFormOpenDay,
        formId: 'lp-open-day',
        title: t('title.openDay'),
        enabled: true,
        componentProps: (next: Next) => ({
          data: next.formData,
        }),
      },
      {
        component: () => FormStepperCallback,
        componentProps: {
          formInfoMsgPropsError: {
            qIconProps: {
              color: 'negative',
              name: 'warning',
              size: '5rem',
            },
            subtitle: { text: 'Error subtitle' },
            title: { text: 'Error title' },
          },
          formInfoMsgPropsSuccess: {
            qIconProps: {
              color: 'primary',
              name: 'success',
              size: '5rem',
            },
            subtitle: { text: t('subtitle.openDaySuccess') },
            title: { text: t('title.openDaySuccess') },
          },
        },
        formId: 'callback',
      },
    ]);
  };

  return { factoryId, getFormStepsLpOpenDay };
};
