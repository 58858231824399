import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useApiListing } from '@/composables/api/listing';
import type { Attribute } from '@/composables/attributesRenderer';
import { useFeatureConfig } from '@/composables/featureConfig';
import { useTheme } from '@/composables/theme';
import useGTM from '@/composables/useGTM';
import { appConfig } from '@/config';
import translations from '@/i18n/translations/components/listingPage.json';
import useAuthStore from '@/store/modules/auth';
import { useListingStore } from '@/store/modules/listing';
import type { TCan } from '@/types';

export const useVarPool = () => {
  const { locale, t } = useI18n({ ...translations, useScope: 'global' });

  const { indexDocuments } = useApiListing();
  const { user } = storeToRefs(useAuthStore());
  const listingStore = useListingStore();
  const {
    auctionRounds,
    isPreview,
    listing,
    listingConfig,
    listingData,
    listingMetaData,
    newlyListedVisible,
  } = storeToRefs(listingStore);
  const { icons } = useTheme();
  const { featureConfig } = useFeatureConfig();

  const cans = computed(() => {
    if (!listing.value || !listingConfig.value || !listingData.value || !listingMetaData.value) {
      return [];
    }

    const isAcquired = listingData.value.listingInfo.statuses.acquired;
    const isAuction = !!listing.value.auction;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _cans: TCan[] = [];

    _cans.push(listing.value.commercializationType, listing.value.category, listing.value.type);

    if (
      !!listing.value.extraInformation.sections.length ||
      !!listing.value.extraInformation.units?.length
    ) {
      _cans.push('extra-info');
    }

    if (auctionRounds.value.length) {
      _cans.push('auction-rounds');

      if (listingData.value.listingInfo.auction?.siblings.length) {
        _cans.push('auction-siblings');
      }
    }

    if (
      featureConfig.value.listing.listingDocuments &&
      (listingMetaData.value.hasDocuments || isPreview.value)
    ) {
      _cans.push('documents');
    }

    if (listingConfig.value.locationInfo?.enabled ?? true) {
      _cans.push('location');
    }

    if (
      featureConfig.value.listing.identificationDetails &&
      listingMetaData.value.hasIdentificationDetails
    ) {
      _cans.push('lro');
    }

    if (listingData.value.propertyInfo.units.length > 1) {
      _cans.push('multi-unit');
    }

    if (
      isAuction &&
      !listing.value.auction.auctionDatePassed() &&
      listing.value.auction.nextRoundComingSoon
    ) {
      _cans.push('auction-next-round');
    }

    if (isAuction && listing.value.auction.dateToBeAnnounced) {
      _cans.push('auction-to-be-announced');
    }

    if (listingMetaData.value.priceUponRequest) {
      _cans.push('price-upon-request');
    }

    if (
      featureConfig.value.listing.similarListings &&
      (listingConfig.value.similarListings?.enabled ?? true)
    ) {
      _cans.push('similar');
    }

    if (listing.value.availabilityStatus === 'underOffer') {
      _cans.push('underOffer');
    }

    if (isAcquired) {
      _cans.push('acquired');
      return _cans;
    }

    // when listing is not acquired...
    if (newlyListedVisible.value) {
      _cans.push('new');
    }

    if (
      appConfig.agentInformationVisible &&
      (!isAuction || listing.value.auction.biddingAvailable())
    ) {
      _cans.push('agent');
    }

    if (
      !appConfig.agentInformationVisible &&
      (!isAuction || listing.value.auction.biddingAvailable())
    ) {
      _cans.push('help');
    }

    if (featureConfig.value.listing.loanRequest) {
      _cans.push('lp-mortgage');
    }

    if (featureConfig.value.listing.infoRequest) {
      _cans.push('lp-interest');
    }

    if (featureConfig.value.listing.openDays && listingConfig.value.openDays.enabled) {
      _cans.push('lp-open-day');
    }

    if (isAuction && featureConfig.value.listing.auctionBid) {
      _cans.push('lp-auction-bid');
    }

    return _cans;
  });

  const badges = computed<Attribute[]>(() => [
    {
      key: 'availability',
      value: listing.value?.commercializationType
        ? t(`section.badges.${listing.value.commercializationType}`)
        : '',
    },
    {
      icon: icons.new,
      key: 'new',
      value: t('section.badges.newlyListed'),
      visible: cans.value.includes('new'),
    },
    {
      icon: icons.hot,
      key: 'hot',
      value: t('section.badges.hot'),
      visible: !!listingStore.hasLabel('hot') && !cans.value.includes('acquired'),
    },
    {
      key: 'for-investment',
      value: t('section.badges.forInvestment'),
      visible: !!listingStore.hasLabel('forInvestment') && !cans.value.includes('acquired'),
    },
  ]);

  const formWidgets = computed(() => []);

  const bootPage = () => {
    listingStore.loadCss();

    watch(user, v => {
      if (!listing.value || !v || listingData.value?.mediaInfo.documents?.length) return;

      indexDocuments(listing.value.id).then(({ data }) => {
        if (!listingData.value) return;

        listingData.value.mediaInfo.documents = data;
      });
    });

    watch(
      () => cans.value.length,
      v => {
        if (!v) return;

        listingStore.hydrateLro();
      }
    );

    watch(listing, v => {
      if (!v) return;

      useGTM('visit_property_page_listing_id', {
        item_id: v.id,
        item_price: v.price,
        item_type: v.type,
        item_category: v.category,
        item_com_type: v.commercializationType === 'auction' ? 'sale' : v.commercializationType,
      });
    });

    watch(
      locale,
      () => {
        listingStore.loadListing();
      },
      { immediate: true }
    );
  };

  return { appConfig, badges, bootPage, cans, formWidgets, icons, t };
};
